import React, { useEffect, useRef, useState, useContext } from 'react';
import { useFormik, FormikValues } from 'formik';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import * as Yup from 'yup';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import { RenderInputProps } from '../models';
import Button from '../../button/button';

const ONLY_NUMBERS_REGEX = /^\d*$/;

const GeneratePin = ({
  HandleGeneratePin,
}: RenderInputProps & { HandleGeneratePin: (pin: string, confirmPin: string) => void }) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          GENERATE_PIN {
            FORM {
              INPUT_PIN
              INPUT_CONFIRM
              BUTTON
              VALIDATION {
                INPUT_PIN
                INPUT_CONFIRM
              }
            }
          }
          SITE {
            LOCK {
              FORM {
                STEP_02 {
                  LABEL
                }
              }
            }
          }
        }
      }
    }
  `);

  const pinInput = useRef<HTMLInputElement>(null);
  const confirmInput = useRef<HTMLInputElement>(null);
  const [showPin, setShowPin] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [pinFocus, setPinFocus] = useState<boolean>(false);
  const [confirmFocus, setConfirmFocus] = useState<boolean>(false);

  const focusInputPin = () => pinInput.current?.focus();
  const focusInputConfirm = () => confirmInput.current?.focus();

  useEffect(focusInputPin, []);

  const { errors, values, handleSubmit, setFieldValue, submitForm } = useFormik({
    initialValues: { pin: '', confirmPin: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .required(t(data).GENERATE_PIN.FORM.VALIDATION.INPUT_PIN)
        .matches(ONLY_NUMBERS_REGEX, t(data).GENERATE_PIN.FORM.VALIDATION.INPUT_PIN),
      confirmPin: Yup.string()
        .required(t(data).GENERATE_PIN.FORM.VALIDATION.INPUT_CONFIRM)
        .oneOf([Yup.ref('pin')], t(data).GENERATE_PIN.FORM.VALIDATION.INPUT_CONFIRM),
    }),
    onSubmit: async (submitValues: FormikValues) => {
      const { pin, confirmPin } = submitValues;

      await HandleGeneratePin(pin, confirmPin);
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="w-full text-left px-5 mt-32">
        <article className="h-24 my-4">
          <label className={`flex text-gray-800 items-center ${pinFocus && 'text-blue'}`}>
            {t(data).GENERATE_PIN.FORM.INPUT_PIN}
          </label>
          <article className="relative">
            <input
              ref={pinInput}
              value={values.pin}
              onChange={event => setFieldValue('pin', event.target.value)}
              onFocus={() => setPinFocus(true)}
              onBlur={() => setPinFocus(false)}
              onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
              onKeyUp={event => event.key === 'Enter' && submitForm()}
              className={`block w-full h-12 outline-none border border-gray-400 py-1 px-2 mb-2 rounded-md text-gray-600 ${
                errors.pin && 'border-red'
              }`}
              maxLength={4}
              css={css`
                &:focus {
                  ${tw`border-blue`}
                }
                -webkit-text-security: ${showPin ? 'none' : 'disc'};
                &::-webkit-credentials-auto-fill-button {
                  visibility: hidden;
                }
              `}
              inputMode="text"
            />
            <div
              className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 p-2 cursor-pointer"
              onClick={() => {
                setShowPin(!showPin);
                focusInputPin();
              }}
            >
              {showPin ? <i className="icon-eye-stroke" /> : <i className="icon-eye-open" />}
            </div>
          </article>
          {errors.pin && (
            <p className="text-red text-xs">
              <span className="icon-exclamation-triangle mr-1" />
              {errors.pin}
            </p>
          )}
        </article>
        <article className="h-24 my-4">
          <label className={`block text-gray-800 mb-1 ${confirmFocus && 'text-blue'}`}>
            {t(data).GENERATE_PIN.FORM.INPUT_CONFIRM}
          </label>
          <article className="relative">
            <input
              ref={confirmInput}
              value={values.confirmPin}
              onChange={event => setFieldValue('confirmPin', event.target.value)}
              onFocus={() => setConfirmFocus(true)}
              onBlur={() => setConfirmFocus(false)}
              onKeyUp={event => event.key === 'Enter' && submitForm()}
              className={`block w-full h-12 outline-none border border-gray-400 py-1 px-2 mb-2 rounded-md text-gray-600 ${
                errors.confirmPin && 'border-red'
              }`}
              maxLength={4}
              css={css`
                &:focus {
                  ${tw`border-blue`}
                }
                -webkit-text-security: ${showConfirm ? 'none' : 'disc'};
                &::-webkit-credentials-auto-fill-button {
                  visibility: hidden;
                }
              `}
              inputMode="text"
            />
            <div
              className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 p-2 cursor-pointer"
              onClick={() => {
                setShowConfirm(!showConfirm);
                focusInputConfirm();
              }}
            >
              {showConfirm ? <i className="icon-eye-stroke" /> : <i className="icon-eye-open" />}
            </div>
          </article>
          {errors.confirmPin && (
            <p className="text-red text-xs">
              <span className="icon-exclamation-triangle mr-1" />
              {errors.confirmPin}
            </p>
          )}
        </article>
        <article className="pt-1 pb-1 sm:px-0 max-w-screen-md mx-auto h-24 relative flex flex-1 flex-col items-center">
          <Button
            color={'primary'}
            type="submit"
            className="block h-12"
          >
            {t(data).SITE.LOCK.FORM.STEP_02.LABEL}
          </Button>
        </article>
      </form>
    </>
  );
};

export { GeneratePin };
