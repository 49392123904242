import React, { useEffect, useContext } from 'react';
import { CompanyContext } from '../../context/company.context';
import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToCustomPage, navigateToError, navigateToFailed, navigateToSuccess } from '../../utils/navigate';

import appService from '../../services/endpoints';
import pages from '../../constants/pages.json';

import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';
import { GeneratePin } from '../../components/forms/pin/generate-pin';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const GeneratePinPage = ({ get, getToken }: PageProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          GENERATE_PIN {
            TITLE
            DESCRIPTION
          }
        }
      }
    }
  `);
  const { getPublicURL } = useContext(CompanyContext);
  const { infoUser, getUser } = useContext(CreditcardContext);
  const token: string = getToken();

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else if (!infoUser?.expirationTime) {
      getUser?.(token);
    }
  }, []);

  const handleSubmit = async (pin: string, confirm: string) => {
    try {
      const { action, url } = await appService.patchGeneratePin(token, pin, confirm);
      console.log('Action', action);
      console.log('URL', url);

      if (action === 'redirect') return navigateToCustomPage(`/${url}`)?.();

      const state = { from: pages.SUCCESS_PAGE.GENERATE_PIN };
      return navigateToSuccess({ state })?.();
    } catch (error) {
      const state = {
        from: pages.FAILED_PAGE.GENERATE_PIN,
      };

      return navigateToFailed({ state })?.();
    }
  };

  return (
    <Layout>
      
      <Portrait bgImage={getPublicURL(`/theme/assets/images/responsive/portrait-bg-2.svg`)}>
        <section className="content relative text-center">
          <h1
            className=" text-4xl text-white my-5 font-light leading-tight tracking-tight"
            dangerouslySetInnerHTML={{ __html: t(data).GENERATE_PIN.TITLE }}
          />

          <p className="text-white">{t(data).GENERATE_PIN.DESCRIPTION}</p>
          <GeneratePin HandleGeneratePin={handleSubmit} />
        </section>
      </Portrait>
    </Layout>
  );
};

export default GeneratePinPage;
